import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Section from '../components/Section';
import TitleBlock from '../components/TitleBlock';
import catImg from '../static/images/404-cat.gif';

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Helmet>
        <body className="bgColor" />
      </Helmet>
      <Section large>
        <TitleBlock title="Soz something went wrong!">
          <img src={catImg} style={{ width: '100%' }} alt="" />
        </TitleBlock>
      </Section>
    </Layout>
  );
}

export default NotFoundPage;
